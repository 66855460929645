#songBoxDiv{
    display: flex;
    flex-direction: row;
    height: 10%;
    padding: 10px;
    margin: 5px;
    gap: 25px;
    border: 1px solid;
    border-radius: 10px;
    flex-grow: inherit;
    flex-shrink: inherit;
}

#currentSongBoxDiv{
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 5px;
    gap: 25px;
    border: 1px solid;
    border-radius: 10px;
    flex-grow: inherit;
    flex-shrink: inherit;
}


#nameAndArtistBox{
    /* display: flex;
    flex-direction: column;
    flex-basis: auto;
    background-color: red;
    
    width: fit-content; */


}

#nameH3{
    margin: 0px;
}

#artistP{
    margin: 0px;
}

#currSongTime{
    margin-left: 10px;
}

#longBox{
    display: flex;
    flex-direction: row;
    /* gap: 50%; */
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
}

#songImage{
    border-radius: 100px;
    max-width: 100%;
}

#songImageBox{
    max-width: 10%;
}

#songImage:hover{
    cursor: pointer;
    box-shadow: -5px 5px 13px black;
    transform: translateY(-3px);
    transform: translateX(3px);
}