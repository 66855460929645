#songsDiv {
    display: flex;
    flex-direction: column;

}

#pageBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: rgb(0, 255, 226);
    background: linear-gradient(145deg, rgba(0, 255, 226, 0.3253676470588235) 0%, rgba(255, 0, 245, 0.24693627450980393) 100%);
}

#songSearch {
    min-height: 30px;
    width: 98%;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
    /* position: absolute-center; */
}

#searchBox {
    display: flex;
    flex-direction: column;
    /* align-content: center; */
    /* justify-items: center; */
    /* align-items: center; */
    /* justify-content: center; */
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
}

#searchList {
    max-height: 400px;
    background-color: white;
    /* position: absolute; */
    overflow: scroll;
    overflow-x: hidden;
    /* top: 45px; */
}

#searchItemBox:hover {
    background-color: lightblue;
    cursor: pointer;
}

#searchItemImage {
    max-width: 30px;
    max-height: 30px;
}

#searchItemBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#clickForQueue:hover {
    cursor: pointer;
}